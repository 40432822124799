import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlatList from '@/components/FlatList';
import { fetchCollectionCatalogItemById } from '../../../../../../redux/actions';
import {
  CollectionCategoryNameText,
  CollectionCategoryWrapper,
  CollectionNameText,
  CollectionWrapper,
} from './AllCollectionsItems.styles';
import { useSSRSelector } from '@/redux/ssrStore';
import { ProductListWrapper } from 'src/components/DesignSystem/Widgets2.0/ProductListWrapper';

function AllCollectionsItems(props) {
  const dispatch = useDispatch();

  const { collections, storeData } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
    storeData: state.storeReducer.store,
  }));

  const { collectionCatalogItems } = useSelector((state: any) => ({
    collectionCatalogItems: state.catalogReducer.collectionCatalogItems,
  }));

  /**
   * this function fetches collection category items when data
   * needs to be loaded
   * @param {number} category
   * @param {number} collection
   */

  function loadMoreData(category, collection) {
    if (storeData?.store_id) {
      dispatch(
        fetchCollectionCatalogItemById({
          storeId: storeData.store_id,
          collectionId: collection.id,
          categoryId: category.id,
        })
      );
    }
  }

  /**
   * this function checks collection object for presence of category and its items
   * as it may or may not be there as we call api when we need to show the items
   * @param {number} collectionId
   * @param {number} categoryId
   * @returns {Array}  of items that needs to be rendered in a collection category
   */

  function returnNestedCollectionData(collectionId, categoryId) {
    const collectionIdValue = collectionCatalogItems[collectionId];
    let categoryIdValue = undefined;
    if (collectionIdValue) {
      categoryIdValue = collectionIdValue[categoryId];
    }
    return categoryIdValue;
  }

  return (
    <>
      {collections?.length
        ? collections.map((collection) => {
            return (
              <CollectionWrapper
                showSortByDropDown={props?.showSortByDropDown}
                showFilterDrawer={props?.showFilterDrawer}
                key={collection?.id}
              >
                {collection?.categories?.map((category) => {
                  const collectionData = returnNestedCollectionData(
                    collection.id,
                    category.id
                  );

                  return (
                    <CollectionCategoryWrapper key={category?.id}>
                      <CollectionCategoryNameText addBottomSpace>
                        <CollectionNameText>{`${collection.name}  /  `}</CollectionNameText>
                        {category.name}
                      </CollectionCategoryNameText>
                      {/* <CollectionItemsContainer> */}
                      <FlatList
                        isLoaderVisible={false}
                        LoaderComponent={null}
                        isNext={collectionData ? false : true}
                        onScrollEnd={() => loadMoreData(category, collection)}
                        renderInsideFragment
                        // add LoaderComponent for loading sections
                        renderList={() => (
                          <ProductListWrapper
                            productList={collectionData}
                            oldCardConfigs={{
                              showButton: true,
                              noTagsReadState: 'fromLength',
                            }}
                            renderAdvancedCard
                            calculateSelfDimensions
                          />
                        )}
                      />
                      {/* </CollectionItemsContainer> */}
                    </CollectionCategoryWrapper>
                  );
                })}
              </CollectionWrapper>
            );
          })
        : null}
    </>
  );
}

export default AllCollectionsItems;
