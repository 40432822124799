import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FlatList from '@/components/FlatList';
// import LoaderIcon from '@/components/Common/LoaderIcon';
import { resetCatalogsList } from '../../../../../../redux/actions';
import { getProducts } from '../../../MiddleBody5/utils';
import {
  CategoriesItemsContainer,
  CategoriesWrapper,
  CategoryListWrapper,
  CategoryName,
} from './AllCategoriesItems.styles';
import { useSSRSelector } from '@/redux/ssrStore';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { GlobalCfeLoader } from '@/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';
import { ProductListWrapper } from 'src/components/DesignSystem/Widgets2.0/ProductListWrapper';

function AllCategoriesItems(props) {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const dispatch = useDispatch();
  const [isFullCatalogLoaded, setIsFullCatalogLoaded] = useState(false);
  const { catalog_items } = useSelector((state: any) => ({
    catalog_items: state.catalogReducer.catalog_items,
  }));
  const {
    storeData: { store_id },
    categories,
  } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    categories: state.catalogReducer.categories,
  }));

  /**
   * the following data has been kept in useRef for the purpose of
   * synchronous data manipulation
   */

  /**
   * categoryPaginationStatus : it holds data for each category if its nextPage
   * is there and array for page number for which the api has been called
   */
  const categoryPaginationStatus = useRef({});

  /**
   * categoryPaginationIterator : its an Iterator to change category nextPage To true
   * when the one category is full rendered and we need to render the next category
   */
  const categoryPaginationIterator = useRef(0);

  /**
   * categoryIdArray : contains categoryId's
   */
  const categoryIdArray = useRef([]);

  /**
   * loader : used to control pagination api. if one api is called till its
   * execution is not done loader will not change state and critical section of code
   * is not invoked
   */
  const loader = useRef(false);

  /**
   * initializing categoryIdArray and categoryPaginationStatus
   */
  useEffect(() => {
    const tempCategoryPaginationStatus = {};
    categories &&
      !!categories?.length &&
      categories.forEach((item, index) => {
        const tempStatusObj = {};
        tempStatusObj['isNext'] = index === 0 ? true : false;
        tempStatusObj['pages'] = [0];
        tempCategoryPaginationStatus[item.id] = tempStatusObj;
      });
    categoryPaginationStatus.current = tempCategoryPaginationStatus;
    categories &&
      !!categories?.length &&
      categories.forEach((item) => {
        categoryIdArray.current.push(item?.id);
      });

    return () => {
      dispatch(resetCatalogsList());
    };
  }, [categories]);

  /**
   * @param {number} categoryId
   * @return {number} page number for api.
   */
  function categoryPageNumber(categoryId) {
    return (
      categoryPaginationStatus.current[categoryId]?.pages?.[
        categoryPaginationStatus.current[categoryId]?.pages?.length - 1
      ] + 1
    );
  }

  /**
   * loads data / is invoked when user Scroll end
   * @param {number} categoryId
   */
  async function loadMoreDataCategories(categoryId) {
    const pageNo = categoryPageNumber(categoryId);
    /** if loader is not there isNExt for category is true and pageNumber api is never called then call api */
    if (
      categoryPaginationStatus.current[categoryId]?.isNext &&
      !loader.current &&
      !categoryPaginationStatus.current[categoryId]?.pages?.includes(pageNo)
    ) {
      loader.current = true;
      if (!store_id) return;
      const { status, isNextPage } = await getProducts(
        categoryId,
        categoryId?.hasOwnProperty('is_custom_tag') ? 1 : 0,
        pageNo,
        store_id
      );
      if (status) {
        categoryPaginationStatus.current[categoryId]?.pages?.push(pageNo);
        categoryPaginationStatus.current[categoryId] &&
          (categoryPaginationStatus.current[categoryId]['isNext'] = isNextPage);
        /** Next page for category does not exist move on to the next category */
        if (!isNextPage && categoryPaginationIterator.current + 1 < categories?.length) {
          /** setting next category nextpage status to true */
          categoryPaginationStatus.current[
            categoryIdArray.current[categoryPaginationIterator.current + 1]
          ]['isNext'] = true;
          /** moving the iterator */
          categoryPaginationIterator.current = categoryPaginationIterator.current + 1;
        } else if (
          /** if we reach the end mark full catalog loaded status to true */
          categoryPaginationIterator.current + 1 ===
          categories?.length
        )
          setIsFullCatalogLoaded(true);
      }
      loader.current = false;
    }
  }

  /**
   *
   * @param {number} categoryId
   * @return {object} category data that needs to be rendered.
   */

  function returnNestedCategoryData(categoryId) {
    let categoryIdValue = undefined;
    categoryIdValue = catalog_items[categoryId];
    return categoryIdValue;
  }

  return (
    <CategoriesWrapper
      showFilterDrawer={props?.showFilterDrawer}
      showSortByDropDown={props?.showSortByDropDown}
    >
      {categories?.map((category) => (
        <CategoryListWrapper key={category?.id}>
          <CategoryName>{category.name}</CategoryName>
          <CategoriesItemsContainer>
            <FlatList
              isLoaderVisible={false}
              isNext={true}
              onScrollEnd={() => loadMoreDataCategories(category.id)}
              renderInsideFragment
              // add LoaderComponent for loading sections
              renderList={() => {
                return (
                  <ProductListWrapper
                    productList={returnNestedCategoryData(category.id)}
                    calculateSelfDimensions
                    renderAdvancedCard
                    oldCardConfigs={{
                      showButton: true,
                      noTagsReadState: 'fromLength',
                    }}
                    customClass="tw-mt-[24px]"
                  />
                );
              }}
            />
            {categoryPaginationStatus.current[category?.id]?.isNext &&
              !isFullCatalogLoaded &&
              loader.current && (
                <div className="flex justify-center mt4 relative">
                  <GlobalCfeLoader
                    type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
                    color={globalStyle?.appLoader?.color}
                  />
                  {/* <LoaderIcon style={{ transform: 'translateX(-50%)' }} /> */}
                </div>
              )}
          </CategoriesItemsContainer>
        </CategoryListWrapper>
      ))}
    </CategoriesWrapper>
  );
}

export default AllCategoriesItems;
