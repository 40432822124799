import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../../utils/constants';

export const AdvMiddleBodyContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  max-width: 100rem;
  margin: 0 auto;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    justify-content: flex-start;
  }
`;

export const AdvMiddleBodyWrapper = styled.article`
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  margin: 32px auto 100px auto;
  width: 100%;
  max-width: 1500px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    margin-top: 22px;
    justify-content: space-between;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  min-width: ${(props) => (props?.visibilty ? '0' : '262px')};
  width: ${(props) => (props?.visibilty ? '0' : 'auto')};
  max-width: ${(props) => (props?.visibilty ? '0' : '100%')};
  height: fit-content;
  background: white;
  border-right: 1px solid #eaeaea;
  position: sticky;
  top: 75px;
  transition: 0.5s width;
  ${(props) => props?.visibilty && `visibility: ${props?.visibilty} ; z-index: -1;`}
  @media screen and (max-width : ${MAX_MOBILE_WIDTH}px) {
    width: 100vw;
    height: 100vh;
    z-index: 6;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: unset;
  }
`;

export const MiddleSection = styled.div`
  width: 100%;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    min-height: 150vh;
  }
`;

export const FilterCtaContainer = styled.div`
  padding-left: 11px;
  padding-right: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: row;
  height: 36px;
  width: fit-content;
  border: 1px solid #d1d1d1;
  align-items: center;
  cursor: pointer;
  ${(props) => (props?.showFilterDrawer ? 'visibility: hidden' : 'visibility : visible')};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    min-width: 50vw;
    justify-content: center;
    height: 48px;
    border-bottom: none;
    border-left: none;
  }
`;

export const FilterText = styled.div`
  margin-left: 10px;
  font-size: 14px;
`;

export const FilterIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SortByCtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    flex-direction: column-reverse;
  }
`;

export const SortFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    position: fixed;
    background: white;
    z-index: 6;
    bottom: 0%;
    left: 0%;
  }
`;

export const DropDownArrowContainer = styled.span`
  margin-left: 14px;
  cursor: pointer;

  > svg {
    transform-box: fill-box;
    transform-origin: center;
    transform: ${(props) =>
      props?.showSortByDropDown ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;

export const SortByDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d1d1d1;
  position: absolute;
  transform: translate(0px, 36px);
  background: white;
  z-index: 6;
  padding-top: 25px;
  min-width: 233px;
  margin-top: 8px;
  z-index: 23;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    bottom: 163%;
    width: 151vw;
    padding-top: 0px;
    margin-top: 0px;
  }
`;

export const SortByDropDownListInput = styled.input.attrs((props) => ({
  type: props?.type ? props?.type : 'radio',
  value: props?.value ? props?.value : '',
}))`
  cursor: pointer;
`;

export const SortByDropDownListElement = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 158px;
  margin-bottom: 20px;
  margin-left: 22px;
  input[type='radio'] {
    accent-color: ${(props) => props?.storeInfo?.theme?.colors?.primary_color};
  }
`;

export const SortByDropDownListLabel = styled.span`
  font-size: 14px;
  font-weight: ${(props) => (props?.isSelected ? 600 : 300)};
  margin-left: 10px;
`;

export const SortByTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 233px;
  padding: 9px;
  border: 1px solid #d1d1d1;
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 50vw;
    height: 48px;
    border-left: none;
    border-bottom: none;
    border-right: none;
    ${(props) =>
      props?.isApplyActive &&
      `background : ${props.storeInfo?.theme?.colors?.primary_color}; color: white`};
  }
`;

export const MobileSortIcon = styled.div`
  display: flex;
  > svg {
    transform-box: fill-box;
    transform-origin: center;
    transform: ${(props) => (props?.rotate ? 'rotate(180deg)' : '')};
  }
`;

export const MobileSortIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const MobileLeftSectionContainer = styled.div`
  position: sticky;
  top: 50vh;
  z-index: 6;
  transform: translate(50vw, 0px);
`;

export const MobileSortHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: 600px;
`;

export const MobileSortHeadingText = styled.span``;

export const MiddleBodyOverflow = styled.div`
  overflow: ${(props) => (props?.isMobile ? 'auto' : 'none')};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    margin-right: 0px;
  }
`;
