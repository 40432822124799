import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../../../../utils/constants';
import {
  CollectionCategoryNameText,
  CollectionItemsContainer,
} from '../AllCollectionsItems/AllCollectionsItems.styles';

export const CategoriesItemsContainer = styled(CollectionItemsContainer)``;

export const CategoriesWrapper = styled.div<{
  showFilterDrawer?: any;
  showSortByDropDown?: any;
}>`
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    margin-left: 0px;
    ${(props) =>
      props?.showSortByDropDown ? 'visibility: hidden' : 'visibility: visible'};
  }
`;

export const CategoryListWrapper = styled.div`
  margin-top: 27px;
`;

export const CategoryName = styled(CollectionCategoryNameText)``;
