/* eslint-disable new-cap */

// eslint-disable-next-line no-undef
const { motion, AnimatePresence } = require('framer-motion');
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTriggerChangeTopHeightMobile from '../../../../hooks/useTriggerChangeTopHeightMobile';
import useTriggerInitialFilterOptions from '../../../../hooks/useTriggerInitialFilterOptions';
import { filtersCounter, saveFilteredOptions, setSortType } from '@/redux/actions';
import { deviceWidth } from '@/utils/deviceWidth';
import { MAX_MOBILE_WIDTH, SortList } from '@/utils/constants';
import { MobileSortByArrow } from '@/assets/svgExports/MobileSortByArrow';
import { MobileFilterIcon } from '@/assets/svgExports/MobileFilterIcon';
import { DropDownArrowSort } from '@/assets/svgExports/DropDownArrowSort';
import useIsComponentMounted from '@/utils/useIsComponentMounted';
import {
  AdvMiddleBodyContainer,
  AdvMiddleBodyWrapper,
  DropDownArrowContainer,
  FilterCtaContainer,
  FilterIconContainer,
  FilterText,
  LeftSection,
  MiddleBodyOverflow,
  MiddleSection,
  MobileLeftSectionContainer,
  MobileSortHeadingContainer,
  MobileSortHeadingText,
  MobileSortIcon,
  MobileSortIconContainer,
  SortByCtaContainer,
  SortByDropDownContainer,
  SortByDropDownListElement,
  SortByDropDownListInput,
  SortByDropDownListLabel,
  SortByTitle,
  SortFilterWrapper,
} from './AdvanceCatalogMiddleBody.styles';
import AllCategoriesItems from './Components/AllCategoriesItems/AllCategoriesItems';
import AllCollectionsItems from './Components/AllCollectionsItems/AllCollectionsItems';
import FilterDrawer from './Components/FilterDrawer/FilterDrawer';
import SortAndFilteredItems from './Components/SortAndFilteredItems/SortAndFilteredItems';
import { setMB7SortAndFilterConfig } from './utils';
import { useSSRSelector } from '@/redux/ssrStore';

function AdvanceCatalogMiddleBody() {
  const isMobile = deviceWidth <= MAX_MOBILE_WIDTH;
  const dispatch = useDispatch();
  const router = useRouter();
  const [showSortByDropDown, setShowSortByDropDown] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showFilterDrawerDelay, setShowFilterDrawerDelay] = useState(false);
  const [mobilePrevFilterOption, setMobilePrevFilterOption] = useState([]);
  const [isSortFilterOptionsActive, setIsSortFilterOptionsActive] = useState(false);

  const { filterAppliedCounter, sortType, filteredOptions, headerRef } = useSelector(
    (state) => ({
      filterAppliedCounter: state.catalogReducer.filterAppliedCounter,
      sortType: state.catalogReducer.sortType,
      filteredOptions: state.catalogReducer.filteredOptions,
      headerRef: state.commonReducer?.headerRef,
    })
  );

  const { store, collections } = useSSRSelector((state) => ({
    collections: state.catalogReducer.collections,
    store: state.storeReducer?.store,
  }));

  const middleBodyRef = useRef();
  const middleBodyContainerRef = useRef();
  const isComponentMounted = useIsComponentMounted();
  const isCollection = collections?.length ? true : false;
  const CollectionOrCategoryId = router?.query?.cid || router?.query?.collectionId;

  /**
   * checks when to show sortAndFilter Cta in mobile base on height
   * from bottom and top of middleBody.
   */
  const showSortAndFilterMobileCta = useTriggerChangeTopHeightMobile(
    middleBodyRef,
    isSortFilterOptionsActive,
    150,
    360
  );

  /** initial api call to get filterOptionList */

  useTriggerInitialFilterOptions(!CollectionOrCategoryId);

  useEffect(() => {
    setIsSortFilterOptionsActive(showSortByDropDown || showFilterDrawer);
  }, [showSortByDropDown, showFilterDrawer]);

  useEffect(() => {
    return () => {
      dispatch(filtersCounter(0, true));
    };
  }, []);

  /**
   * on mobile if filterDrawer is Active stop
   * Body from overflowing
   */

  useEffect(() => {
    if (isSortFilterOptionsActive && isMobile) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
      setTimeout(() => {
        middleBodyRef.current.scrollIntoView();
      }, 600);
    } else document.getElementsByTagName('body')[0].style.overflowY = 'auto';

    return () => {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    };
  }, [isSortFilterOptionsActive]);

  function handleSortByClick() {
    // simulates apply condition in Mobile when FilterDrawer is open
    if (isMobile && showFilterDrawer) {
      setShowFilterDrawer(!showFilterDrawer);
      setTimeout(() => {
        setShowFilterDrawerDelay(!showFilterDrawerDelay);
      }, 525);
      setMobilePrevFilterOption(filteredOptions);
      return;
    }
    // simulates apply condition in case when sorting drowdown is active
    else if (isMobile && showSortByDropDown) {
      setShowSortByDropDown(!showSortByDropDown);
      return;
    } else setShowSortByDropDown(!showSortByDropDown);
  }

  useEffect(() => {
    if (isMobile && headerRef.current) {
      showFilterDrawer
        ? (headerRef.current.style.display = 'none')
        : (headerRef.current.style.display = '');
    }
  }, [showFilterDrawer]);

  function getCorrectedFilterAppliedCounter() {
    let newFilteredAppliedCounter = 0;
    mobilePrevFilterOption &&
      mobilePrevFilterOption.forEach((item) => {
        !!item?.sub_filter?.length &&
          item?.sub_filter.forEach((subFilterItem) => {
            if (subFilterItem?.is_selected) {
              newFilteredAppliedCounter++;
            }
          });
      });
    dispatch(filtersCounter(newFilteredAppliedCounter, true));
  }

  function getFilterOptionSelectedArray(localfilterOptions) {
    const selectedSubFilters = [];
    !!localfilterOptions?.length &&
      localfilterOptions.forEach((filterOptionItem) => {
        !!filterOptionItem.sub_filters?.length &&
          filterOptionItem.sub_filters.forEach((item) => {
            item?.is_selected && selectedSubFilters.push(item?.name);
          });
      });
    return selectedSubFilters;
  }

  /**
   * checks whether two array are equal or not
   * @param {*} prevFilterOptions FilterOption State in Mobile before Current Session
   * @param {*} currentFilterOptions FilterOption State in Current Session
   * @returns {boolean} if both arrays are same return true else false
   */

  function isFilterOptionAsPrevious(prevFilterOptions, currentFilterOptions) {
    const prevSelectedSubFilters = getFilterOptionSelectedArray(prevFilterOptions);
    const currentSelectedSubFilters = getFilterOptionSelectedArray(currentFilterOptions);
    if (prevSelectedSubFilters.length === currentSelectedSubFilters.length) {
      return prevSelectedSubFilters.every((element, index) => {
        if (element === currentSelectedSubFilters[index]) {
          return true;
        }
        return false;
      });
    }
    return false;
  }

  /**
   * when filter drawer close in mobile the checked states need to be reset
   * to previous state so does filterApplied counter
   * @returns {*}
   */

  function handleShowFilterDrawerAndClose() {
    if (isMobile && showSortByDropDown) {
      showSortByDropDown && handleSortByClick();
      showFilterDrawer && setShowFilterDrawer(!showFilterDrawer);
      return;
    } else if (isMobile && showFilterDrawerDelay) {
      if (!isFilterOptionAsPrevious(mobilePrevFilterOption, filteredOptions))
        getCorrectedFilterAppliedCounter();
      !!mobilePrevFilterOption?.length &&
        dispatch(saveFilteredOptions(mobilePrevFilterOption));
    }
    setShowFilterDrawer(!showFilterDrawer);
    setTimeout(() => {
      setShowFilterDrawerDelay(!showFilterDrawerDelay);
    }, 525);
  }

  function handleSortItemClick(e, sortItem) {
    e?.stopPropagation();
    dispatch(setSortType(sortItem.type));
    setMB7SortAndFilterConfig({ sortTypeChanged: true });
    !isMobile && handleSortByClick();
  }

  function getNameFromSortType() {
    return (
      SortList &&
      !!SortList?.length &&
      SortList.find((item) => item.type === sortType)
    )?.name;
  }
  function renderFilterDrawer() {
    return isMobile ? (
      <MobileLeftSectionContainer>
        <LeftSection>
          <FilterDrawer handleShowFilterDrawer={handleShowFilterDrawerAndClose} />
        </LeftSection>
      </MobileLeftSectionContainer>
    ) : (
      <LeftSection>
        <FilterDrawer handleShowFilterDrawer={handleShowFilterDrawerAndClose} />
      </LeftSection>
    );
  }

  function renderFilterDrawerDesktop() {
    return (
      <AnimatePresence>
        {showFilterDrawer && (
          <motion.div
            key="filterDrawer"
            initial={{ opacity: 0, x: -100, zIndex: 3 }}
            transition={{ duration: 0.5 }}
            animate={{ opacity: 1, x: 0, zIndex: 3 }}
            exit={{ opacity: 0, x: -100, zIndex: 3 }}
          >
            {renderFilterDrawer()}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  function renderFilterDrawerMobile() {
    return (
      <AnimatePresence>
        {showFilterDrawer && (
          <motion.div
            key="filterDrawer"
            initial={{ opacity: 0, x: 0, zIndex: -1 }}
            transition={{ duration: 0.5 }}
            animate={{ opacity: 1, x: 0, zIndex: 3 }}
            exit={{ opacity: 0, x: 0, zIndex: -1 }}
          >
            {renderFilterDrawer()}
          </motion.div>
        )}
      </AnimatePresence>
    );
  }

  function renderLeftSection() {
    return (
      <>
        {isMobile ? renderFilterDrawerMobile() : renderFilterDrawerDesktop()}
        {!(showFilterDrawer ? showFilterDrawer : showFilterDrawerDelay) && !isMobile && (
          <LeftSection visibilty={'hidden'} />
        )}
      </>
    );
  }

  function renderFilterCta() {
    return (
      <FilterCtaContainer
        id="FilterCtaContainer"
        onClick={handleShowFilterDrawerAndClose}
        showFilterDrawer={isMobile ? false : showFilterDrawer}
      >
        <FilterIconContainer>
          {isMobile ? (
            isSortFilterOptionsActive ? null : (
              <MobileFilterIcon />
            )
          ) : (
            <MobileFilterIcon />
          )}
        </FilterIconContainer>
        <FilterText>
          {isMobile ? (isSortFilterOptionsActive ? 'Close' : 'Filters') : 'Filters'}
        </FilterText>
      </FilterCtaContainer>
    );
  }

  function renderSortArrowIcon() {
    return (
      <MobileSortIconContainer>
        <MobileSortIcon>
          <MobileSortByArrow />
        </MobileSortIcon>
        <MobileSortIcon rotate={true}>
          <MobileSortByArrow />
        </MobileSortIcon>
      </MobileSortIconContainer>
    );
  }

  function renderMobileSortByHeading() {
    return (
      <MobileSortHeadingContainer>
        <MobileSortHeadingText>Sort By</MobileSortHeadingText>
      </MobileSortHeadingContainer>
    );
  }

  function sortByDesktopText() {
    return (
      <span className="flex flex-row">
        Sort By:
        <span className="ml6px fw6 flex justify-center align-center">
          {getNameFromSortType()}
        </span>
      </span>
    );
  }

  function renderSortByCta() {
    return (
      <SortByCtaContainer onClick={handleSortByClick}>
        <SortByTitle
          storeInfo={store}
          isApplyActive={isMobile && isSortFilterOptionsActive}
        >
          {isMobile && (isSortFilterOptionsActive ? null : renderSortArrowIcon())}
          {isMobile ? (
            isSortFilterOptionsActive ? (
              'Apply'
            ) : (
              'Sort By'
            )
          ) : (
            <>{sortByDesktopText()}</>
          )}

          {!isMobile && (
            <DropDownArrowContainer showSortByDropDown={showSortByDropDown}>
              <DropDownArrowSort />
            </DropDownArrowContainer>
          )}
        </SortByTitle>
        {showSortByDropDown && (
          <SortByDropDownContainer>
            {isMobile && renderMobileSortByHeading()}
            {SortList.map((sortItem) => {
              return (
                <SortByDropDownListElement
                  storeInfo={store}
                  key={`Sort-DropDown-list-${sortItem.name}`}
                  onClick={(e) => {
                    handleSortItemClick(e, sortItem);
                  }}
                >
                  <SortByDropDownListInput
                    checked={sortItem.type === sortType ? true : false}
                    type="radio"
                  />
                  <SortByDropDownListLabel
                    isSelected={sortItem.type === sortType ? true : false}
                  >
                    {sortItem.name}
                  </SortByDropDownListLabel>
                </SortByDropDownListElement>
              );
            })}
          </SortByDropDownContainer>
        )}
      </SortByCtaContainer>
    );
  }

  function SortAndFilterCtaTab() {
    if (isMobile && !showSortAndFilterMobileCta) return null;
    return (
      isComponentMounted && (
        <SortFilterWrapper>
          {renderFilterCta()}
          {renderSortByCta()}
        </SortFilterWrapper>
      )
    );
  }

  function renderCategoryItemList() {
    return (
      isComponentMounted && (
        <MiddleBodyOverflow isMobile={isMobile}>
          {SortAndFilterCtaTab()}
          <AllCategoriesItems
            showSortByDropDown={showSortByDropDown}
            showFilterDrawer={showFilterDrawer}
          />
        </MiddleBodyOverflow>
      )
    );
  }

  function renderSortAndFilteredItems() {
    return (
      isComponentMounted && (
        <MiddleBodyOverflow isMobile={isMobile}>
          {SortAndFilterCtaTab()}
          <SortAndFilteredItems
            middleBodyContainerRef={middleBodyContainerRef}
            showSortByDropDown={showSortByDropDown}
            showFilterDrawer={showFilterDrawer}
          />
        </MiddleBodyOverflow>
      )
    );
  }

  const collectionCategoryItemsList = () => {
    return (
      <div
        className={`sortAndFilterCollection collection-category-items ${
          isMobile ? '' : 'mr76px'
        }`}
      >
        {SortAndFilterCtaTab()}
        <AllCollectionsItems
          showFilterDrawer={showFilterDrawer}
          showSortByDropDown={showSortByDropDown}
        />
      </div>
    );
  };

  function renderMiddleBodyAnimated() {
    return (
      <>
        {isMobile ? (
          renderMiddleSection()
        ) : (
          <motion.div
            key="middleBodyAnimated"
            initial={{ x: 0 }}
            transition={{ duration: 0.5 }}
            animate={showFilterDrawer ? { x: 0 } : {}}
            className="tw-w-full"
          >
            {renderMiddleSection()}
          </motion.div>
        )}
      </>
    );
  }

  function renderMiddleSection() {
    return (
      <MiddleSection ref={middleBodyRef}>
        {filterAppliedCounter || sortType || CollectionOrCategoryId
          ? renderSortAndFilteredItems()
          : isCollection
            ? collectionCategoryItemsList()
            : renderCategoryItemList()}
      </MiddleSection>
    );
  }

  return (
    <AdvMiddleBodyWrapper ref={middleBodyContainerRef}>
      {renderLeftSection()}
      <AdvMiddleBodyContainer>{renderMiddleBodyAnimated()}</AdvMiddleBodyContainer>
    </AdvMiddleBodyWrapper>
  );
}

export default AdvanceCatalogMiddleBody;
