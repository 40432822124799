import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../../../../utils/constants';

export const CollectionWrapper = styled.div<{
  showSortByDropDown?: any;
  showFilterDrawer?: any;
}>`
  margin-left: ${(props) => (props?.showFilterDrawer ? '76px' : '')};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    margin-left: 0px;
    ${(props) =>
      props?.showSortByDropDown ? 'visibility: hidden' : 'visibility: visible'};
  }
`;

export const CollectionCategoryWrapper = styled.div`
  margin-top: 27px;
`;

export const CollectionCategoryNameText = styled.p<{ addBottomSpace?: boolean }>`
  color: #414141;
  font-size: 17px;
  font-weight: 500;
  margin: ${(props) => `0 0 ${props.addBottomSpace ? '27px' : 0} 0`};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

export const CollectionNameText = styled.span`
  color: #797979;
  font-size: 14px;
  font-weight: 400;
`;

export const CollectionItemsContainer = styled.div`
  > section {
    margin-top: 24px;
    display: grid;
    justify-self: center;
    justify-items: center;
    grid-auto-rows: auto;

    @media screen and (min-width: ${MAX_MOBILE_WIDTH}px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 3rem;
      row-gap: 2rem;
      justify-self: baseline;
      justify-items: baseline;
    }
    @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
      row-gap: 32px;
      justify-self: center;
      justify-items: center;
    }
  }
`;
